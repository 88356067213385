body {
  margin: 0;
}

.vendor-logo {
  height: 70px;
}

.auth-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.onboard-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}

.ant-layout,
.ant-layout-footer {
  background-color: #fbfbfb;
}

.inner-page-main {
  margin-top: 25px;
}

.inner-page-main .page-title {
  margin: 0;
  font-size: 20px;
  color: #1e1e1e;
}

.app-table tr th {
  background: #e6e6e6 !important;
  color: #2e2e2e !important;
}

.app-table tr td,
.app-table tr th {
  border-inline-start: 1px solid #b8b8b8 !important;
  border-inline-end: none !important;
  border-bottom: 1px solid #b8b8b8 !important;

}

.app-table tr th {
  border-top: 1px solid #b8b8b8 !important;
}

.app-table tr td:last-child,
.app-table tr th:last-child {
  border-inline-end: 1px solid #b8b8b8 !important;
}

.modal-form-vertical .ant-form-item {
  margin-bottom: 15px;
}

.modal-form-vertical .ant-form-item .user-input::placeholder {
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
}

.modal-form-vertical .ant-form-item .user-input {
  background: #e6e6e6;
  border: transparent;
  padding: 15px;
  color: #2e2e2e;
  font-size: 16px;
}

.modal-form-vertical .ant-form-item .user-select {
  height: 55px;
}

.modal-form-vertical .ant-form-item .user-select .ant-select-selector {
  background: #e6e6e6;
  border: transparent;
  /* padding: 15px; */
  color: #2e2e2e;
  font-size: 16px;
}

.modal-form-action-btn {
  background-color: #e4ccff;
  color: #2e2e2e;
  border: transparent;
  box-shadow: none;
  padding: 28px;
  font-size: 16px;
  font-weight: bold;
}

.modal-form-action-btn:hover {
  background-color: #e4ccff !important;
  color: #2e2e2e !important;
  border: transparent;
  box-shadow: none;
  padding: 28px;
  font-size: 16px;
  font-weight: bold;
}

.modal-form-cancel-btn {
  background-color: gray;
  color: #fff;
  border: transparent;
  box-shadow: none;
  padding: 28px;
  font-size: 16px;
  font-weight: bold;
}

.modal-form-cancel-btn:hover {
  background-color: gray !important;
  color: #fff !important;
  border: transparent;
  box-shadow: none;
  padding: 28px;
  font-size: 16px;
  font-weight: bold;
}

.select-primary-inner {
  min-height: 55px;
  width: 200px;
}

.select-primary-inner .ant-select-selector {
  border: transparent !important;
  background-color: #e6e6e6 !important;
  padding: 20px !important;
}

.select-primary-inner-multiple .ant-select-selector {
  padding: 0 !important;
  min-height: 55px;
}

/* .select-gametype-container {
  margin-top: 25px;
} */

.activate-btn {
  background-color: #28a745;
}

.view-btn-link {
  background: #1677ff !important;
  box-shadow: 0 2px 0 rgba(255, 38, 5, 0.06) !important;
  color: #fff !important;
  font-size: 14px;
  line-height: 1.5714285714285714;
  height: 24px;
  padding: 0px 7px;
  border-radius: 4px;
}

.text-capitalize {
  text-transform: capitalize;
}

.search-container {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-container div:last-child {
  margin-left: auto;
}

.search-container .table-search-box {
  width: 300px;
  height: 45px;
}

.search-container .table-filter-select {
  width: 140px;
  height: 45px;
}

.search-container .table-search-box .ant-input {
  background: #fff;
  border-color: #d9d9d9;
  padding: 10px;
  color: #2e2e2e;
  font-size: 16px;
  height: 45px;
}

.search-container .table-search-box .ant-btn {
  padding: 26px;
  height: 45px;
}

.text-center {
  text-align: center;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.mt25 {
  margin-top: 25px;
}

.modal-form-vertical .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
  color: #fff;
  background: #1677ff;
  border-color: #1677ff;
}

.player-bucket-primary-btn {
  background-color: #e4ccff;
  color: #2e2e2e;
  border: transparent;
  box-shadow: none;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
}

.theme-fieldset {
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px !important;
}

.theme-legend {
  border-bottom: none !important;
  width: max-content !important;
  padding: 0 5px !important;
  margin-bottom: 0 !important;
}

.no-margin-divider {
  margin: 5px 0;
}

.inner-form {
  margin-top: 50px;
}

.no-margin-h4 {
  margin: 0;
}

.machine-select-disabled .ant-select-selection-item {
  color: #000 !important;
}

.customer-hostory-table {
  width: 100%;
}

.customer-hostory-table td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.customer-hostory-table tr:nth-child(even) {
  background-color: #dddddd;
}

.cusomer-history-title {
  margin: 15px 0 !important;
}

.export-btn {
  background-color: #e4ccff;
  color: #2e2e2e;
  border: transparent;
  box-shadow: none;
  padding: 22px 25px;
  font-size: 16px;
  font-weight: bold;
}

.superadmin-logo {
  font-size: 25px;
}

.nested-table .ant-table-row.ant-table-row-level-1 {
  display: none;
}

.date-picker-padding {
  padding: 10px;
}

.without-h-padding {
  padding: 28px 25px !important;
}